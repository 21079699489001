import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import "./EditTaskModal.scss"
import {Button} from "react-bootstrap";
import {ITask} from "../services/TaskService";

export default function EditTaskModal({task, onShow, show}: { task: ITask, onShow: () => void, show: boolean }) {
    const [state, setState] = useState<ITask>({
        algorithm: task.algorithm,
        status: task.status
    });

    function handleSubmit(event: any) {
        event.preventDefault();
        fetch(`/api/task?name=${encodeURIComponent(state.algorithm)}&status=${encodeURIComponent("In Progress")}`)
            .then(response => response.json())
            .then(state => setState(state));
        onShow();
    }

    function onCleanData() {
        fetch(`/api/clean_data`)
            .then(response => response.json())
            .then(state => setState(state));
        onShow();
    }

    return (
        <Modal show={show} onHide={onShow} className={"modalContent"}>
            <Modal.Header closeButton>
                <Modal.Title>Task Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Algorithm: {task.algorithm}</p>
                <p>Status: {task.status}</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="algorithm">algorithm:</label>
                    <select
                        name="Algorithm"
                        defaultValue={task.algorithm}
                        className="form-control"
                        id="algorithm"
                        onChange={(e) => setState({algorithm: e.target.value})}>
                        <option>Smile</option>
                        <option>Emotion</option>
                        <option>Object</option>
                        <option>Like</option>
                        <option>Disable</option>
                    </select>
                    <div className={"buttons"}>
                        <div className="editTasksBtn">
                            <Button onClick={() => (window.confirm("Do you want to clear data?")) && onCleanData()}>Clean</Button>
                        </div>
                        <div className="editTasksBtn">
                            <input type="submit" className="btn btn-success"/>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}