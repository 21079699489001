import React, { useState } from "react";
import EditTaskModal from "./EditTaskModal";
import { ITask } from "../services/TaskService";

const Header = ({task, imageCount}: {task: ITask, imageCount: number}) => {
	const [show, setShow] = useState(false);

	return (
		<div className="header">
			<h1>
				scople
			</h1>
			<p>{imageCount}</p>
			{task && <>
				<ul className="icons" onClick={() => setShow(true)}>
					<li></li>
					<li></li>
					<li></li>
				</ul>
				<EditTaskModal task={task} onShow={() => setShow(false)} show={show}/>
			</>}
		</div>
	);
};

export default Header;