import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Objects from "./components/Object";
import {YoloImages} from "./components/YoloImages";

function App() {

  return(

    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/yolo_images" element={<YoloImages />} />
          <Route path="/" element={<Objects />} />
        </Routes>
      </div>
    </BrowserRouter>
  )

}

export default App;