import "./MainContainer.scss";
import React from "react";

const MainContainer = ({children}: { children?: React.ReactNode }) => {
    return (
        <div className={"mainContainer"}>
            {children}
        </div>
    );
};

export default MainContainer