import {YoLoService} from "../services/YoLoService";
import {useEffect, useState} from "react";

export const YoloImages = () => {
    const yoloService = new YoLoService();
    const [images, setImages] = useState([]);

    function loadYoloImages() {
        yoloService.getYoloImages().then(yoloImages => {
            setImages(yoloImages);
            if (yoloImages.length !== 0)
                yoloService.getYoloImage(yoloImages[0]).then(yoloImage => {
                    console.log("asdf");
                });
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            loadYoloImages();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            lol {images.length}
        </div>
    );
};