export interface IYolo {
    _id: string;
    classes: string;
    count: number;
}

export interface IYoloImage {
    image: string
}

export class YoLoService {

    public async getAllObjects(): Promise<any> {
        const response = await fetch('/api/yolo');
        return await response.json() as IYolo[];
    }

    public async getYoloImages(): Promise<any> {
        const response = await fetch('/api/yolo_images');
        return await response.json() as IYoloImage[];
    }

    public async getYoloImage(id: string): Promise<any> {
        const response = await fetch(`/api/yolo_image/${id}`);
        return await response   .json() as IYoloImage;
    }

}