import "./Layout.scss";
import React from "react";

const Layout = ({children}: { children?: React.ReactNode}) => {
    return (
        <div className={"layoutContainer"}>
            {/*<Header task={task}/>*/}
            <div className={"content"}>
                {children}
            </div>
        </div>
    );
};

export default Layout