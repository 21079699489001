import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";
import { ImageCountService } from "../services/ImageCount";
import DetectList from "./DetectList";
import Layout from "./shared/Layout";
import { YoLoService } from "../services/YoLoService";
import Header from "./Header";
import { TaskService } from "../services/TaskService";

function Objects()
{
	const yoloService = new YoLoService();
	const taskService = new TaskService();
	const imageCountService = new ImageCountService();

	const [objects, setObjects] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [imageCount, setImageCount] = useState(0);

	function loadObjects()
	{
		yoloService.getAllObjects().then(objects => {
			setObjects(objects);
		});
	}

	useEffect(() => {
		const interval = setInterval(() => {
			loadObjects();
			loadTasks();
			loadImageCount();
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	function loadTasks()
	{
		taskService.getAllTasks().then(task => {
			setTasks(task);
		});
	}

	function loadImageCount()
	{
		imageCountService.getImageCount().then(count => {
			setImageCount(count);
		});
	}

	return (
		<Layout>
			<Header task={tasks[0]} imageCount={imageCount}/>
			<DetectList detects={objects}/>
		</Layout>
	);
}

export default Objects;
