import "./DetectBlock.scss";
import MainContainer from "./shared/MainContainer";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {IYolo} from "../services/YoLoService";

const DetectBlock = ({detect}: { detect: IYolo }) => {

    return (
        <MainContainer>
            <div className={"detectBlockContainer"}>
                <div className={"left"}>
                    <CircularProgressbar value={detect.count} text={`${detect.count}`}
                                         styles={buildStyles({
                                             strokeLinecap: 'butt',
                                             textSize: '16px',
                                             pathTransitionDuration: 0.5,
                                             pathColor: '#679758',
                                             textColor: '#ffffff',
                                             trailColor: '#d6d6d6',
                                         })}/>
                </div>
                <div className={"right"}>
                    <div className={"text"}>
                        <div>{detect._id}</div>
                    </div>
                </div>
            </div>
        </MainContainer>
    );
};

export default DetectBlock