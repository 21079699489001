import DetectBlock from "./DetectBlock";
import "./DetectList.scss";
import {IYolo} from "../services/YoLoService";

const DetectList = ({detects}: { detects: IYolo[] }) => {


    return (
        <div className={"detectListContainer"}>
            <div className={"detectListContent"}>
                {detects.map((detect, index) =>
                    <DetectBlock detect={detect} key={index}/>
                )}
            </div>
        </div>
    );
};

export default DetectList